@tailwind base;
@tailwind components;
@tailwind utilities;

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 2px solid #ededff;
  -webkit-text-fill-color: #212121;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
  color: #212121;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }

  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(6, end) 2000ms infinite;
    animation: ellipsis steps(6, end) 2000ms infinite;
    content: '\2026';
    /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 40px;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 40px;
    }
  }

  .input-wrapper {
    @apply relative flex flex-col items-center w-full my-4;
  }

  .custom-input {
    @apply block rounded-[15px]
    px-4 pt-6 pb-2
    w-5/6
    lg:w-1/3
    text-lg text-lead
    placeholder:text-lightGrey
    bg-white
    border-solid border-2 border-sheerLavender
    appearance-none
    focus:border-solid focus:border-2 focus:border-sheerBlue
    outline-none;
  }

  .input-label {
    @apply absolute
    text-lg text-lightGrey
    duration-300 transform -translate-y-4 scale-75
    top-[18px] left-[13%] z-10 origin-[0]
    lg:left-[34.5%]
    peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0
    peer-focus:scale-75 peer-focus:-translate-y-4
    pointer-events-none;
  }

  .input-error-state {
    @apply bg-palePeach
    border-solid border-2 !border-deepCoral
    outline-none
    autofill:shadow-[inset_0_0_0px_1000px_#FBE9E2];
  }

  .input-error-message {
    @apply w-5/6 pl-2 lg:w-1/3;
  }

  .btn-rounded {
    @apply flex items-center justify-center w-5/6 p-4 m-2 text-lg align-middle transition-all bg-white rounded-full lg:w-1/3 enabled:hover:bg-opacity-25 disabled:bg-opacity-40 xs:text-base;
  }

  .btn-primary {
    @apply flex items-center justify-center w-11/12 p-4 m-2 text-lg align-middle transition-all bg-white rounded-md shadow-md lg:w-64 enabled:hover:bg-opacity-50 lg:text-xl;
  }

  .square-btn-primary {
    @apply w-10/12
    h-16
    lg:w-[200px]
    lg:h-[200px]
    flex
    flex-col
    justify-center
    lg:justify-start
    items-center
    text-lg
    lg:text-xl
    m-4
    lg:m-8
    lg:pt-10
    text-darkNavy
    bg-palestLavender
    lg:hover:bg-opacity-20
    lg:hover:text-darkPurple
    rounded-[30px]
    transition-all
    ease-in-out
    duration-200;
  }

  .card-background {
    @apply flex flex-col items-center px-4 w-full gap-4 py-4 mb-4 lg:p-8 lg:mt-6 rounded-[20px] bg-white font-arizona-sans lg:w-5/12 lg:min-h-fit lg:shadow-5;
  }

  .bg-purple-gradient {
    @apply bg-[linear-gradient(300deg,_rgba(220,_227,_249,_0.00)_46.43%,_rgba(155,_179,_255,_0.30)_92.7%),_linear-gradient(162deg,_rgba(111,_0,_204,_0.00)_24.88%,_rgba(111,_0,_204,_0.20)_108.48%)];
  }

  .shadow-5 {
    @apply shadow-[0px_16px_36px_-20px_rgba(12,1,47,0.17),0px_16px_64px_0px_rgba(47,5,88,0.02),0px_12px_60px_0px_rgba(0,0,0,0.05)];
  }

  .shadow-6 {
    @apply shadow-[0px_16px_36px_-20px_var(--Neutral-color-Neutral-Alpha-7,rgba(12,1,47,0.17)),0px_16px_64px_0px_var(--Neutral-color-Neutral-Alpha-2,rgba(47,5,88,0.02)),0px_12px_60px_0px_var(--Overlays-Black-Alpha-3,rgba(0,0,0,0.05))];
  }

  .form-wrapper {
    @apply flex flex-col items-center justify-center w-full gap-4 px-4 pb-4 pt-2;
  }

  .button-base {
    @apply w-full flex justify-center items-center gap-3 h-auto py-3 text-[18px] rounded-full transition-all ease-in-out select-none;
  }

  .button-dark {
    @apply button-base font-light bg-[#29004C] text-[#F3F1F5] enabled:hover:bg-[#4B0082] disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7];
  }

  .button-medium {
    @apply button-base text-[#29004C] bg-[#EEE2FF] disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7] enabled:hover:bg-[#DCC5FF] enabled:hover:text-[#29004C] font-medium;
  }

  .button-light {
    @apply button-base border-[1px] lg:border-[1.5px] border-[#45007F] bg-white font-medium hover:bg-[#DCC5FF] enabled:hover:text-[#211F26] disabled:bg-[#F3F1F5] disabled:text-[#BCBAC7];
  }

  .button-alert {
    @apply button-base border-[1px] lg:border-[1.5px] border-[#CA244D] text-white bg-[#E54666] disabled:bg-[#E54666] disabled:text-[#BCBAC7] enabled:hover:bg-[#CA244D] font-medium;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
